import { render, staticRenderFns } from "./admin-root.vue?vue&type=template&id=2196f503"
import script from "./admin-root.vue?vue&type=script&lang=js"
export * from "./admin-root.vue?vue&type=script&lang=js"
import style0 from "./admin-root.vue?vue&type=style&index=0&id=2196f503&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/workspace/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2196f503')) {
      api.createRecord('2196f503', component.options)
    } else {
      api.reload('2196f503', component.options)
    }
    module.hot.accept("./admin-root.vue?vue&type=template&id=2196f503", function () {
      api.rerender('2196f503', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/admin/root/admin-root.vue"
export default component.exports