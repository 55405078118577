var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { md12: "", xs12: "" } },
            [
              _c(
                "v-card",
                { staticClass: "card pa-4" },
                [
                  _c("div", { staticClass: "header d-flex flex-column" }, [
                    _c(
                      "h3",
                      { staticClass: "secondary-text text-lg-left form-title" },
                      [_vm._v("Wachtwoord")]
                    ),
                    _c("p", { staticClass: "body-1 ma-0" }, [
                      _vm._v("Wijzig hier je wachtwoord."),
                    ]),
                  ]),
                  _c("v-divider", { staticClass: "mt-5" }),
                  _c(
                    "div",
                    { staticClass: "pl-10" },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          model: {
                            value: _vm.disableSave,
                            callback: function ($$v) {
                              _vm.disableSave = $$v
                            },
                            expression: "disableSave",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "mt-4 d-flex flex-row align-start py-4",
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "ma-0 profile-item body-1 grey--text text--lighten-1",
                                },
                                [_vm._v("Huidig wachtwoord")]
                              ),
                              _c("v-text-field", {
                                staticClass: "profile-item",
                                attrs: {
                                  outlined: "",
                                  rules: [..._vm.rules.first],
                                  "append-icon": _vm.showCurrent
                                    ? "mdi-eye"
                                    : "mdi-eye-off",
                                  type: _vm.showCurrent ? "text" : "password",
                                },
                                on: {
                                  "click:append": function ($event) {
                                    _vm.showCurrent = !_vm.showCurrent
                                  },
                                },
                                model: {
                                  value: _vm.currentPassword,
                                  callback: function ($$v) {
                                    _vm.currentPassword = $$v
                                  },
                                  expression: "currentPassword",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row align-start pb-4" },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "ma-0 profile-item body-1 grey--text text--lighten-1",
                                },
                                [_vm._v("Nieuw wachtwoord")]
                              ),
                              _c("v-text-field", {
                                staticClass: "profile-item",
                                attrs: {
                                  outlined: "",
                                  rules: _vm.rules.second.concat(
                                    _vm.rules.secondEmpty,
                                    _vm.rules.secondLength
                                  ),
                                  "append-icon": _vm.showNew
                                    ? "mdi-eye"
                                    : "mdi-eye-off",
                                  type: _vm.showNew ? "text" : "password",
                                },
                                on: {
                                  "click:append": function ($event) {
                                    _vm.showNew = !_vm.showNew
                                  },
                                },
                                model: {
                                  value: _vm.values[0],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.values, 0, $$v)
                                  },
                                  expression: "values[0]",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row align-start pb-4" },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "ma-0 profile-item body-1 grey--text text--lighten-1",
                                },
                                [_vm._v("Bevestig nieuw wachtwoord")]
                              ),
                              _c("v-text-field", {
                                staticClass: "profile-item",
                                attrs: {
                                  outlined: "",
                                  rules: _vm.rules.third.concat(
                                    _vm.rules.thirdEmpty,
                                    _vm.rules.thirdLength
                                  ),
                                  "append-icon": _vm.confirmNew
                                    ? "mdi-eye"
                                    : "mdi-eye-off",
                                  type: _vm.confirmNew ? "text" : "password",
                                },
                                on: {
                                  "click:append": function ($event) {
                                    _vm.confirmNew = !_vm.confirmNew
                                  },
                                },
                                model: {
                                  value: _vm.values[1],
                                  callback: function ($$v) {
                                    _vm.$set(_vm.values, 1, $$v)
                                  },
                                  expression: "values[1]",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "mb-2 d-flex justify-end align-center" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "ml-4",
                              attrs: {
                                text: "",
                                "aria-label": "Login",
                                to: { path: "/forgot-password" },
                              },
                            },
                            [_vm._v("Wachtwoord vergeten")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "white--text ml-4",
                              attrs: {
                                disabled: !_vm.disableSave,
                                color: "blue-grey",
                                "aria-label": "Wijzig wachtwoord",
                              },
                              on: { click: _vm.saveNewPass },
                            },
                            [_vm._v("Wachtwoord opslaan")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }