var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { md12: "", xs12: "" } },
            [
              _c(
                "v-card",
                { staticClass: "card pa-5" },
                [
                  _c("div", { staticClass: "header d-flex align-center" }, [
                    _c(
                      "div",
                      {
                        staticClass: "avatarEdit mr-5 d-flex align-end",
                        on: {
                          click: function ($event) {
                            _vm.dialog = true
                          },
                        },
                      },
                      [
                        _c(
                          "v-avatar",
                          { attrs: { color: "grey lighten-4", size: "62" } },
                          [
                            _c("img", {
                              attrs: {
                                src:
                                  _vm.basicUrl + "/images/" + _vm.user.avatar,
                                alt: _vm.user.firstName,
                              },
                            }),
                          ]
                        ),
                        _c("v-icon", { staticClass: "ml-n5 mb-n1" }, [
                          _vm._v(" mdi-pencil-circle "),
                        ]),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "d-flex flex-column" }, [
                      _c(
                        "h3",
                        {
                          staticClass: "secondary-text text-lg-left form-title",
                        },
                        [_vm._v("Hallo " + _vm._s(_vm.user.firstName))]
                      ),
                      _c("p", { staticClass: "body-1 ma-0" }, [
                        _vm._v("Hieronder een overzicht van jouw gegevens."),
                      ]),
                    ]),
                  ]),
                  _c("v-divider", { staticClass: "mt-5" }),
                  _c("div", { staticClass: "pl-10" }, [
                    _c(
                      "div",
                      { staticClass: "mt-4 d-flex flex-row align-center py-4" },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "profile-item body-1 grey--text text--lighten-1",
                          },
                          [_vm._v("Naam")]
                        ),
                        _c("p", { staticClass: "profile-item body-1" }, [
                          _vm._v(_vm._s(_vm.user.lastName)),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row align-center pb-4" },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "profile-item body-1 grey--text text--lighten-1",
                          },
                          [_vm._v("Voornaam")]
                        ),
                        _c("p", { staticClass: "profile-item body-1" }, [
                          _vm._v(_vm._s(_vm.user.firstName)),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row align-center pb-4" },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "profile-item body-1 grey--text text--lighten-1",
                          },
                          [_vm._v("E-mail")]
                        ),
                        _c("p", { staticClass: "profile-item body-1" }, [
                          _vm._v(_vm._s(_vm.user.email)),
                        ]),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row align-center pb-4" },
                      [
                        _c(
                          "p",
                          {
                            staticClass:
                              "profile-item body-1 grey--text text--lighten-1",
                          },
                          [_vm._v("Rol")]
                        ),
                        _c("p", { staticClass: "profile-item body-1" }, [
                          _vm._v(_vm._s(_vm.user.role)),
                        ]),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "700px" },
          model: {
            value: _vm.dialog,
            callback: function ($$v) {
              _vm.dialog = $$v
            },
            expression: "dialog",
          },
        },
        [
          _vm.dialog
            ? _c("picture-uploader", {
                attrs: { avatarSize: _vm.avatarSize },
                on: {
                  uploadAvatar: function ($event) {
                    _vm.onUploadAvatar($event)
                    _vm.dialog = false
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }