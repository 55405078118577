var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-card-title", { staticClass: "px-12 pt-8 pb-0" }, [
        _vm.edit
          ? _c("h3", [_vm._v("Project aanpassen")])
          : _c("h3", [_vm._v("Project aanmaken")]),
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-layout",
            { attrs: { row: "", wrap: "" } },
            [
              _c(
                "v-flex",
                { staticClass: "pa-8" },
                [
                  _c(
                    "v-form",
                    { ref: "form" },
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "3" } },
                            [
                              _c("v-text-field", {
                                staticClass: "mx-auto m-0",
                                attrs: {
                                  type: "number",
                                  "hide-details": "",
                                  label: "Omniboxx-id",
                                },
                                model: {
                                  value: _vm.omniboxxId,
                                  callback: function ($$v) {
                                    _vm.omniboxxId = $$v
                                  },
                                  expression: "omniboxxId",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "9" } },
                            [
                              _c("v-text-field", {
                                staticClass: "mx-auto m-0",
                                attrs: { "hide-details": "", label: "Naam" },
                                model: {
                                  value: _vm.name,
                                  callback: function ($$v) {
                                    _vm.name = $$v
                                  },
                                  expression: "name",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "6" } },
                            [
                              _c("v-text-field", {
                                staticClass: "mx-auto m-0",
                                attrs: {
                                  "hide-details": "",
                                  label: "Nummer van gebouw / project",
                                },
                                model: {
                                  value: _vm.buildingNumber,
                                  callback: function ($$v) {
                                    _vm.buildingNumber = $$v
                                  },
                                  expression: "buildingNumber",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "pb-0 mt-8",
                              attrs: { cols: "12", sm: "12" },
                            },
                            [_c("h3", [_vm._v("Adres")])]
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "8" } },
                            [
                              _c("v-text-field", {
                                staticClass: "mx-auto m-0",
                                attrs: {
                                  "hide-details": "",
                                  label: "Straatnaam",
                                },
                                model: {
                                  value: _vm.address.street,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.address, "street", $$v)
                                  },
                                  expression: "address.street",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "4" } },
                            [
                              _c("v-text-field", {
                                staticClass: "mx-auto m-0",
                                attrs: { "hide-details": "", label: "Nummer" },
                                model: {
                                  value: _vm.address.number,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.address, "number", $$v)
                                  },
                                  expression: "address.number",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "4" } },
                            [
                              _c("v-text-field", {
                                staticClass: "mx-auto m-0",
                                attrs: { "hide-details": "", label: "Zip" },
                                model: {
                                  value: _vm.address.zip,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.address, "zip", $$v)
                                  },
                                  expression: "address.zip",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { attrs: { cols: "12", sm: "4" } },
                            [
                              _c("v-text-field", {
                                staticClass: "mx-auto m-0",
                                attrs: { "hide-details": "", label: "Stad" },
                                model: {
                                  value: _vm.address.city,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.address, "city", $$v)
                                  },
                                  expression: "address.city",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-card-actions",
        { staticClass: "mb-2" },
        [
          _c("v-spacer"),
          _c(
            "v-btn",
            {
              attrs: { color: "blue darken-1", text: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close")
                },
              },
            },
            [_vm._v(" Annuleren ")]
          ),
          _vm.canEdit
            ? _c(
                "v-btn",
                {
                  attrs: {
                    color: "blue darken-1",
                    text: "",
                    disabled: !_vm.isComplete,
                  },
                  on: { click: _vm.saveClicked },
                },
                [
                  _vm.edit
                    ? _c("span", [_vm._v("Project aanpassen")])
                    : _c("span", [_vm._v("Project aanmaken")]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }