var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "container",
      attrs: { "grid-list-md": "", "text-xs-center": "" },
    },
    [
      _c(
        "v-layout",
        { attrs: { row: "", wrap: "" } },
        [
          _c(
            "v-flex",
            { attrs: { md12: "", xs12: "" } },
            [
              _c(
                "v-card",
                { staticClass: "card pa-4" },
                [
                  _c("div", { staticClass: "header-container" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "header d-flex justify-space-between align-center",
                      },
                      [
                        _c("div", [
                          _c(
                            "h1",
                            {
                              staticClass:
                                "secondary-text text-lg-left form-title",
                            },
                            [_vm._v("Tenants")]
                          ),
                        ]),
                        _c("div"),
                      ]
                    ),
                  ]),
                  _c(
                    "v-card",
                    { attrs: { flat: "", elevation: 0 } },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "d-flex justify-space-between px-0" },
                        [
                          _c("filter-data-dialog-component", {
                            staticClass: "filter--container",
                            attrs: {
                              searchLabel: "Zoeken",
                              acceptedFilters: _vm.acceptedFilters,
                              uniqueFilterStoreName: "filter$tenantList",
                              showFilters: false,
                              showSearchBar: true,
                            },
                            on: { filterData: _vm.setFilterOnList },
                          }),
                        ],
                        1
                      ),
                      _c("v-data-table", {
                        attrs: {
                          "item-key": "tableKey",
                          headers: _vm.headers,
                          menu: _vm.menu,
                          items: _vm.tenants,
                          options: _vm.options,
                          "server-items-length": _vm.totalCount,
                          loading: _vm.isLoading,
                          "footer-props": {
                            "items-per-page-options": [5, 10, 15],
                          },
                        },
                        on: {
                          "update:options": function ($event) {
                            _vm.options = $event
                          },
                          "update:page": _vm.setSkip,
                          "update:items-per-page": _vm.setLimit,
                          "update:sort-by": _vm.setSort,
                          "update:sort-desc": _vm.setSort,
                          "click:row": _vm.clickItem,
                        },
                      }),
                      _c(
                        "v-card-title",
                        [
                          _c(
                            "div",
                            { staticClass: "px-3" },
                            [
                              _c("v-select", {
                                staticClass: "filter-select",
                                attrs: {
                                  dense: "",
                                  outlined: "",
                                  "hide-details": "",
                                  small: "",
                                  multiple: "",
                                  label: "Jaar",
                                  clearable: "",
                                  items: _vm.columnValueList("year"),
                                },
                                model: {
                                  value: _vm.filters.year,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.filters, "year", $$v)
                                  },
                                  expression: "filters.year",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("v-spacer"),
                          _c("v-text-field", {
                            staticClass: "search-box",
                            attrs: {
                              "append-icon": "mdi-search",
                              label: "Search",
                              "single-line": "",
                              filled: "",
                              "hide-details": "",
                            },
                            model: {
                              value: _vm.search,
                              callback: function ($$v) {
                                _vm.search = $$v
                              },
                              expression: "search",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-data-table",
                        {
                          attrs: {
                            headers: _vm.getHeaders,
                            items: _vm.filteredTenants,
                            search: _vm.search,
                            "custom-sort": _vm.customSort,
                            "item-key": "email",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "header",
                              fn: function ({ props }) {
                                return [
                                  _c(
                                    "tr",
                                    { staticClass: "grey lighten-3" },
                                    _vm._l(props.headers, function (header) {
                                      return props.headers
                                        ? _c("th", { key: header.text }, [
                                            _vm.filters.hasOwnProperty(
                                              header.value
                                            )
                                              ? _c(
                                                  "div",
                                                  { staticClass: "px-3" },
                                                  [
                                                    !header.value.includes(
                                                      "Date"
                                                    )
                                                      ? _c("v-autocomplete", {
                                                          staticClass:
                                                            "filter-select",
                                                          attrs: {
                                                            dense: "",
                                                            outlined: "",
                                                            "hide-details": "",
                                                            small: "",
                                                            multiple: "",
                                                            clearable: "",
                                                            items:
                                                              _vm.columnValueList(
                                                                header.value
                                                              ),
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.filters[
                                                                header.value
                                                              ],
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.filters,
                                                                header.value,
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "filters[header.value]",
                                                          },
                                                        })
                                                      : _c(
                                                          "v-menu",
                                                          {
                                                            ref: _vm.menu[
                                                              header.value
                                                            ],
                                                            refInFor: true,
                                                            attrs: {
                                                              "close-on-content-click": false,
                                                              transition:
                                                                "scale-transition",
                                                              "offset-y": "",
                                                              "min-width":
                                                                "290px",
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "activator",
                                                                  fn: function ({
                                                                    on,
                                                                    attrs,
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-text-field",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  value:
                                                                                    _vm
                                                                                      .formatedDates[
                                                                                      header
                                                                                        .value
                                                                                    ],
                                                                                  "prepend-inner-icon":
                                                                                    "mdi-calendar",
                                                                                  readonly:
                                                                                    "",
                                                                                  dense:
                                                                                    "",
                                                                                  outlined:
                                                                                    "",
                                                                                  "hide-details":
                                                                                    "",
                                                                                  small:
                                                                                    "",
                                                                                  clearable:
                                                                                    "",
                                                                                },
                                                                              on: {
                                                                                input:
                                                                                  function (
                                                                                    $event
                                                                                  ) {
                                                                                    _vm.filters[
                                                                                      header.value
                                                                                    ] =
                                                                                      null
                                                                                  },
                                                                              },
                                                                            },
                                                                            "v-text-field",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        )
                                                                      ),
                                                                    ]
                                                                  },
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                            model: {
                                                              value:
                                                                _vm.menu[
                                                                  header.value
                                                                ],
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.menu,
                                                                    header.value,
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "menu[header.value]",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-date-picker",
                                                              {
                                                                attrs: {
                                                                  "no-title":
                                                                    "",
                                                                  scrollable:
                                                                    "",
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.filters[
                                                                      header
                                                                        .value
                                                                    ],
                                                                  callback:
                                                                    function (
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.filters,
                                                                        header.value,
                                                                        $$v
                                                                      )
                                                                    },
                                                                  expression:
                                                                    "filters[header.value]",
                                                                },
                                                              }
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e()
                                    }),
                                    0
                                  ),
                                ]
                              },
                            },
                            {
                              key: "body",
                              fn: function ({ items }) {
                                return [
                                  _c(
                                    "tbody",
                                    _vm._l(items, function (item) {
                                      return _c(
                                        "tr",
                                        [
                                          !_vm.extended
                                            ? _c(
                                                "td",
                                                { staticClass: "text-xs-left" },
                                                [_vm._v(_vm._s(item.tenantId))]
                                              )
                                            : _vm._e(),
                                          _c(
                                            "td",
                                            { staticClass: "text-xs-left" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatDate")(
                                                    item.startDate
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-xs-left" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("formatDate")(
                                                    item.endDate
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-xs-left" },
                                            [
                                              _vm._v(
                                                _vm._s(item.unitId.project)
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  attrs: {
                                                    to: {
                                                      name: "unitDetail",
                                                      params: {
                                                        id: item.unitId._id,
                                                      },
                                                    },
                                                    target: "_blank",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.unitId.roomNumber
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-xs-left" },
                                            [_vm._v(_vm._s(item.language))]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-xs-left" },
                                            [_vm._v(_vm._s(item.name))]
                                          ),
                                          _c(
                                            "td",
                                            { staticClass: "text-xs-left" },
                                            [_vm._v(_vm._s(item.email))]
                                          ),
                                          _vm._l(
                                            _vm.payments,
                                            function (payment) {
                                              return _vm.extended
                                                ? _c(
                                                    "td",
                                                    {
                                                      staticClass:
                                                        "justify-center",
                                                    },
                                                    [
                                                      _vm.showBullet(
                                                        item.unitId,
                                                        item.year,
                                                        payment.id
                                                      )
                                                        ? [
                                                            item[payment.id] &&
                                                            item[payment.id]
                                                              .checked
                                                              ? _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "mr-2",
                                                                    attrs: {
                                                                      color:
                                                                        "green",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.paymentDialogItem =
                                                                            {
                                                                              _id: item._id,
                                                                              payment,
                                                                            }
                                                                          _vm.paymentDialog = true
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " mdi-checkbox-marked-circle-outline "
                                                                    ),
                                                                  ]
                                                                )
                                                              : item[
                                                                  payment.id
                                                                ] &&
                                                                item[payment.id]
                                                                  .status
                                                              ? _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "mr-2",
                                                                    attrs: {
                                                                      color:
                                                                        "orange",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.paymentDialogItem =
                                                                            {
                                                                              _id: item._id,
                                                                              payment,
                                                                            }
                                                                          _vm.paymentDialog = true
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " mdi-alert-circle-outline "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "v-icon",
                                                                  {
                                                                    staticClass:
                                                                      "mr-2",
                                                                    attrs: {
                                                                      color:
                                                                        "red",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          _vm.paymentDialogItem =
                                                                            {
                                                                              _id: item._id,
                                                                              payment,
                                                                            }
                                                                          _vm.paymentDialog = true
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " mdi-close-circle-outline "
                                                                    ),
                                                                  ]
                                                                ),
                                                          ]
                                                        : _vm._e(),
                                                    ],
                                                    2
                                                  )
                                                : _vm._e()
                                            }
                                          ),
                                          _vm.extended
                                            ? _c(
                                                "td",
                                                {
                                                  staticClass: "justify-center",
                                                },
                                                [
                                                  item.imgFront &&
                                                  !item.passportChecked
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: {
                                                            color: "orange",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.dialogItem =
                                                                item
                                                              _vm.dialog = true
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " mdi-alert-circle-outline "
                                                          ),
                                                        ]
                                                      )
                                                    : item.imgFront &&
                                                      item.passportChecked
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: {
                                                            color: "green",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.dialogItem =
                                                                item
                                                              _vm.dialog = true
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " mdi-checkbox-marked-circle-outline "
                                                          ),
                                                        ]
                                                      )
                                                    : _c(
                                                        "v-icon",
                                                        {
                                                          staticClass: "mr-2",
                                                          attrs: {
                                                            color: "red",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " mdi-close-circle-outline "
                                                          ),
                                                        ]
                                                      ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "td",
                                            { staticClass: "text-center" },
                                            [
                                              item.unitId.contractRemarksStatus
                                                ? _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { color: "grey" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.commentContractDialogItem =
                                                            item
                                                          _vm.commentContractDialog = true
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" mdi-chat ")]
                                                  )
                                                : _c(
                                                    "v-icon",
                                                    {
                                                      attrs: { color: "grey" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.commentContractDialogItem =
                                                            item
                                                          _vm.commentContractDialog = true
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " mdi-chat-outline "
                                                      ),
                                                    ]
                                                  ),
                                            ],
                                            1
                                          ),
                                          _vm.extended
                                            ? _c(
                                                "td",
                                                {
                                                  staticClass: "justify-center",
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: { color: "grey" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.goToPaymentPage(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " mdi-link-variant "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.extended
                                            ? _c(
                                                "td",
                                                {
                                                  staticClass: "justify-center",
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.mailItem(
                                                            item
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " mdi-email-outline "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.canEdit && !_vm.extended
                                            ? _c(
                                                "td",
                                                {
                                                  staticClass: "justify-center",
                                                },
                                                [
                                                  item.mailLog
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              ;(_vm.MaillogDialog = true),
                                                                (_vm.MaillogDialogItem =
                                                                  {
                                                                    logs: item.mailLog,
                                                                    email:
                                                                      item.email,
                                                                  })
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " mdi-email-outline "
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.canEdit && !_vm.extended
                                            ? _c(
                                                "td",
                                                {
                                                  staticClass: "justify-center",
                                                },
                                                [
                                                  _c(
                                                    "v-icon",
                                                    {
                                                      staticClass: "mr-2",
                                                      attrs: { color: "grey" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.confirmDeleteDialogItem =
                                                            item
                                                          _vm.confirmDeleteDialog = true
                                                        },
                                                      },
                                                    },
                                                    [_vm._v(" mdi-delete ")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    }),
                                    0
                                  ),
                                ]
                              },
                            },
                          ]),
                        },
                        [
                          _c(
                            "template",
                            { slot: "no-data" },
                            [
                              _c(
                                "v-alert",
                                {
                                  attrs: {
                                    value: true,
                                    color: "grey",
                                    icon: "mdi-warn",
                                  },
                                },
                                [_vm._v(" Sorry, geen gebruikers gevonden ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _vm.canEdit
                        ? _c("v-file-input", {
                            staticClass: "file-input",
                            attrs: {
                              outlined: "",
                              "prepend-inner-icon": "mdi-paperclip",
                              "prepend-icon": "",
                              accept: "text/comma-separated-values",
                              placeholder: "bestand uploaden",
                            },
                            model: {
                              value: _vm.file,
                              callback: function ($$v) {
                                _vm.file = $$v
                              },
                              expression: "file",
                            },
                          })
                        : _vm._e(),
                      _vm.canEdit
                        ? _c(
                            "v-btn",
                            {
                              attrs: { outlined: "", loading: _vm.loading },
                              on: { click: _vm.uploadFile },
                            },
                            [_vm._v("bestand opladen (vanuit Omnibox)")]
                          )
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-3",
                          attrs: { outlined: "" },
                          on: {
                            click: function ($event) {
                              _vm.createTenantDialog = true
                            },
                          },
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              staticClass: "mr-2",
                              attrs: { color: "primary" },
                            },
                            [_vm._v("mdi-account-plus")]
                          ),
                          _vm._v(" Creatie huurder "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }