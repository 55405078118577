var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    {
      staticClass: "container",
      attrs: { "grid-list-md": "", "text-xs-center": "" },
    },
    [
      _c(
        "v-flex",
        { staticClass: "ma-5 mb-0", attrs: { md12: "", xs12: "" } },
        [
          _c("v-card", { staticClass: "card pa-4" }, [
            _c(
              "div",
              { staticClass: "header" },
              [
                _c(
                  "h1",
                  { staticClass: "secondary-text text-lg-left form-title" },
                  [_vm._v(" Support "), _c("v-spacer")],
                  1
                ),
                _c(
                  "v-tabs",
                  {
                    staticClass: "tabs",
                    attrs: { "show-arrows": "" },
                    model: {
                      value: _vm.active,
                      callback: function ($$v) {
                        _vm.active = $$v
                      },
                      expression: "active",
                    },
                  },
                  [
                    _c("v-tabs-slider"),
                    !_vm.canSeeEnties
                      ? _c(
                          "v-tab",
                          {
                            attrs: {
                              href: "#intredes",
                              "active-class": "active",
                            },
                          },
                          [_vm._v("Opvolging intredes")]
                        )
                      : _vm._e(),
                    !_vm.canSeeStayers
                      ? _c(
                          "v-tab",
                          {
                            attrs: {
                              href: "#blijvers",
                              "active-class": "active",
                            },
                          },
                          [_vm._v("Opvolging blijvers")]
                        )
                      : _vm._e(),
                    !_vm.canSeeRent
                      ? _c(
                          "v-tab",
                          {
                            attrs: {
                              href: "#huurluik",
                              "active-class": "active",
                            },
                            on: {
                              click: function ($event) {
                                !_vm.updateRentList
                              },
                            },
                          },
                          [_vm._v("Opvolging rent")]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c("div", { staticClass: "grid text-xs-center" }, [
        _c(
          "div",
          { attrs: { id: "profile-form" } },
          [
            _c(
              "v-tabs-items",
              {
                model: {
                  value: _vm.active,
                  callback: function ($$v) {
                    _vm.active = $$v
                  },
                  expression: "active",
                },
              },
              [
                !_vm.canSeeEnties
                  ? _c(
                      "v-tab-item",
                      { attrs: { value: "intredes" } },
                      [_c("entries-list", { attrs: { extended: true } })],
                      1
                    )
                  : _vm._e(),
                !_vm.canSeeStayers
                  ? _c(
                      "v-tab-item",
                      { attrs: { value: "blijvers" } },
                      [_c("staying-list", { attrs: { extended: false } })],
                      1
                    )
                  : _vm._e(),
                !_vm.canSeeRent
                  ? _c(
                      "v-tab-item",
                      { attrs: { value: "huurluik" } },
                      [_c("rent-list")],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }